import { useEffect } from "react";
import { useNavigate } from "react-router";
import { endpoints, routeNames } from "static";
import { RootState, useTypedDispatch, useTypedSelector } from "store";
import { apiService, currentUserRole } from "store/slices/authSlice";
import { getcookietoken, removeCookieToken } from "utils/Storage";
import { PageLoader } from "app/shared";

const { CURRENT_USER_ROLE } = endpoints;

export const CheckLogin = () => {
  console.log("inside login ------------------------------- ");
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const {
    details,
    organization_id,
    users_organizations = [],
  } = useTypedSelector((state: RootState) => state.authReducer || {});
  const token = getcookietoken();

  useEffect(() => {
    if (process.env.REACT_APP_SSO_ENABLE === "true") {
      console.log("after sso redirect to expense ---> ", token);
      organization_id && dispatch(currentUserRole({ url: CURRENT_USER_ROLE }));
      dispatch(
        apiService({
          data: { accessToken: token },
          method: "post",
          url: `/auth/accessToken`,
        })
      )
        .unwrap()
        .then((res) => {
          console.log("then-------", res);
          removeCookieToken();
          if (res) localStorage.setItem("sso_check", "true");
          if (!users_organizations.length) {
            console.log("check login inside if 40");
            navigate(routeNames.REGISTER_ORGANIZATION, {
              state: { from: routeNames.LOGIN },
            });
          } else if (details?.platform_type) {
            console.log("check login inside else if 45");
            navigate(routeNames.DASHBOARD);
          } else {
            console.log("check login inside else 49");
            navigate("/", { replace: true });
          }
        })
        .catch((err) => {
          console.log("error", err);
          console.log({ erroToDispatch: err });
        });
    }
    //eslint-disable-next-line
  }, []);
  return <PageLoader />;
};
