import { Tabs } from "antd";
const { TabPane } = Tabs;
interface TabProps {
  tabsInfo: { tab: string; icon?: any; component: () => void }[];
  tabPosition?: "top" | "left",
  iconPosition?: "left" | "right"
}

export const TabView = ({ tabsInfo, tabPosition = "top", iconPosition = "left" }: TabProps) => {
  const onChange = (key: string) => {
    console.log(key);
  };
  return (
    <Tabs defaultActiveKey="0" onChange={onChange} style={{ padding: 24 }} size={"large"} tabPosition={tabPosition}>
      {tabsInfo.map((menu: any, index: any) => (
        <TabPane
          tab={iconPosition == "left" ?
            <span>
              {" "}
              {menu.icon} {menu.tab}{" "}
            </span>
            :
            <span>
              {" "}
              {menu.tab} {menu.icon}{" "}
            </span>
          }
          key={`${index}`}
        >
          {menu.component()}
        </TabPane>
      ))}
    </Tabs>
  );
};