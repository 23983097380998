import { useState, useEffect } from "react"
import { Col, Descriptions, Divider, Drawer, Row, Space, Typography, } from "antd";
import { useAxios } from "app/Hooks";
import { RootState, useTypedSelector } from "store";
import { endpoints } from "static";


const { ORGANIZATIONS } = endpoints
type orgnizationprops = {
    toggleDrawer: () => void
    open: boolean
}
const OrganizationProfile = ({ toggleDrawer, open }: orgnizationprops) => {
    const { organization_id } = useTypedSelector(({ authReducer }: RootState) => authReducer)
    const [org_data, setOrgData] = useState<any>()
    const { callAxios } = useAxios()


    useEffect(() => {
        if (organization_id != null) {
            callAxios({
                url: `${ORGANIZATIONS}/${organization_id}`
            }).then((res) => {
                setOrgData(res)
            })
        }
    }, [])

    return (
        <>
            <Drawer width={440} placement="right" closable={false} onClose={toggleDrawer} open={open}>
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Title level={4}>Organization Profile</Typography.Title>

                </Space>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Descriptions title="Name">
                            <Descriptions.Item>{org_data?.name}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Descriptions title="Active">
                            <Descriptions.Item>{org_data?.is_active == 1 ? "true" : "false"}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Descriptions title="Platform">
                            <Descriptions.Item>{org_data?.platform_type}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Descriptions title="Shared">
                            <Descriptions.Item>{org_data?.is_shared ? "true" : "false"}</Descriptions.Item>
                        </Descriptions>
                    </Col>

                </Row>
            </Drawer>
        </>
    );
};

export default OrganizationProfile;