/** @format */
import { useEffect, useState } from "react";
import { Checkbox, Col, Divider, Row, Space, Typography } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { endpoints } from "static";
import { Buttonx, PageLoader, Toast } from "app/shared";
import { useGetGeneralModulesQuery, useGetOrganizationsQuery } from "store/query/organization";
import { useAxios, useSharedOrganization, useStore } from "app/Hooks";
import { useLocation, useNavigate } from "react-router";

const Modules = ({ toggleInnerDrawer, innerDrawer }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { callAxios } = useAxios();
  // const [, startTransition] = useTransition();
  const [module, setModule] = useState<CheckboxValueType[]>();
  const { data: general_modules = [] } = useGetGeneralModulesQuery("", {
    refetchOnMountOrArgChange: true,
  });
  const { refetch } = useGetOrganizationsQuery("");
  const [hasContentLoading, setHasContentLoading] = useState(true);
  const { sharedOrg, ownerOrg, handleModulePermission } = useSharedOrganization();
  const { handleUpdateUserWithRoles, users_organizations } = useStore();

  useEffect(() => {
    if (!!general_modules) {
      setHasContentLoading(false);
    }
  }, [general_modules]);

  useEffect(() => {
    !innerDrawer && !!sharedOrg?.modules_permissions.length
      ? setModule([
          ...sharedOrg.modules_permissions.filter((val) => val.status).map((val) => val.name),
        ])
      : setModule(general_modules.filter((val: any) => val.status).map((val: any) => val.name));
  }, [general_modules, innerDrawer, sharedOrg]);

  const selectModule = (checkedValues: any) => setModule(checkedValues);

  const handlesubmit = () => {
    const last_created_org = users_organizations[users_organizations.length - 1];
    let payload = {
      organization_id: Number(innerDrawer ? last_created_org?.id : ownerOrg?.id),
      modules: handleModulePermission(module),
    };
    callAxios({
      method: "post",
      url: endpoints.BMS_MODULE_INTEGRATIONS,
      data: payload,
    }).then((res) => {
      if (res) {
        Toast({ message: "Module Shared Successfully" });
        innerDrawer && toggleInnerDrawer();
        refetch();

        callAxios({
          url: endpoints.USER_PROFILE,
        }).then((res) => {
          res && handleUpdateUserWithRoles(res);
          navigate(location.pathname, { state: { permissionModal: false } });
        });
      }
    });
    // setTimeout(() => window.location.reload(), 4000);
  };
  // Commented on purpose
  // const handleUnshare = () => {
  //   callAxios({
  //     method: "delete",
  //     url: `${endpoints.UNSHARE_ORGANIZATION}/${ownerOrg?.id}`,
  //   }).then(() => {
  //     Toast({ message: "Organization Unshared successfully" });
  //     callAxios({
  //       url: endpoints.USER_PROFILE,
  //     }).then((res) => {
  //       res && handleUpdateUserWithRoles(res, true);
  //       navigate("/", { replace: true });
  //     });
  //   });
  // };
  return (
    <>
      {hasContentLoading ? (
        <PageLoader />
      ) : (
        <div className="main_wrapper">
          {/* {!innerDrawer && <Breadcrumbx name="Modules" setting={true} />} */}
          <div className="_container">
            <Row>
              <Col span={24}>
                <Typography.Title level={4} className="form_heading">
                  Enable the modules required for your business
                </Typography.Title>
              </Col>
              <div className="form_box">
                <Col span={18} style={{ top: "15px" }}>
                  <div className="flexbox">
                    <div className="form_group module-box">
                      <Typography.Text>
                        Item,Customer & Vendor are available by default in Seebiz Expense.
                      </Typography.Text>
                    </div>
                    <Checkbox.Group
                      value={module}
                      style={{ width: "100%", paddingBottom: "10px" }}
                      onChange={selectModule}
                    >
                      <Space direction="vertical" style={{ width: "100%" }}>
                        {!innerDrawer
                          ? sharedOrg?.modules_permissions?.map((module) => (
                              <Col span={12} key={module.general_modules_id}>
                                <Checkbox
                                  disabled={
                                    !!(
                                      module.name === "Item" ||
                                      module.name === "Contact" ||
                                      module.name === "Expense"
                                    )
                                  }
                                  value={module.name}
                                >
                                  {module.name === "Contact" ? "Customer & Vendor" : module.name}
                                </Checkbox>{" "}
                              </Col>
                            ))
                          : general_modules?.map((module: any) => (
                            module.active ?
                              (<Col span={12} key={module.id}>
                                <Checkbox
                                  disabled={!!(module.name === "Item" || module.name === "Contact")}
                                  value={module.name}
                                >
                                  {module.name === "Contact" ? "Customer & Vendor" : module.name}
                                </Checkbox>{" "}
                              </Col>) : null
                            ))}
                      </Space>
                    </Checkbox.Group>
                    <Typography.Text>
                      Note: You can change these details later in settings, if needed.
                    </Typography.Text>
                    <Divider />
                    {(innerDrawer || ownerOrg?.is_owner || ownerOrg?.exact_owner) && (
                      <Space style={{ width: "90%" }}>
                        <Buttonx
                          className="btn-form-size btn-primary"
                          btnText={innerDrawer ? "Next" : "Share"}
                          clickHandler={handlesubmit}
                        />
                        {/* Comment for purpose */}
                        {/* {ownerOrg?.platform_type === "ims,books" && (         
                      <Popconfirm
                        title="Your Organization data will be removed after unshare. Are you sure to perform this action?"
                        okText="Yes"
                        onConfirm={handleUnshare}>
                        <Button type="link" style={{ marginBottom: "21px" }}>
                          UnShare
                        </Button>
                      </Popconfirm>
                    )} */}
                      </Space>
                    )}
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
export default Modules;
