/** @format */

import { Avatar, Typography, List, Tag, Card } from "antd";
import { Labels, endpoints, routeNames } from "static";
import { Icons, Toast } from "app/shared";
import { MenuListProps } from "./Types";
import { useAxios, useStore } from "app/Hooks";
import { useNavigate } from "react-router";
import { useGetOrganizationsQuery } from "store/query/organization";

const { Meta } = List.Item;
const { OWNER, INVITEE } = Labels;
const { BsCheckCircleFill, CgOrganisation } = Icons;
const { USER_PROFILE } = endpoints;
const { DASHBOARD } = routeNames;

export const Listx = ({ openInnerDrawer }: MenuListProps) => {
  const { data: { organizations = [] } = {}, isFetching } = useGetOrganizationsQuery("", {
    refetchOnMountOrArgChange: true,
  });

  const { callAxios } = useAxios();
  const navigate = useNavigate();
  const { handleUpdateUserWithRoles, organization_id } = useStore();
  const handleClick = (item: any) => {
    return new Promise((resolve) => {
      if (item.platform_type === "books" && item.is_owner === false)
        resolve(Toast({ message: "Permission Not Granted By Admin", type: "info" }));
      else if (item.platform_type === "books" && (item.is_owner || item.exact_owner))
        resolve(openInnerDrawer(item));
      else if (["expense", "expense,books"].includes(item.platform_type)) {
        callAxios({
          url: USER_PROFILE,
          org: item.id,
        }).then((res) => {
          Toast({ message: "Organization Switch Successfully" });
          res && resolve(handleUpdateUserWithRoles(res));
        });
        navigate(DASHBOARD);
      }
    });
  };

  return (
    <List
      itemLayout="horizontal"
      loading={isFetching}
      dataSource={organizations}
      renderItem={(item: any) => (
        <Card
          hoverable={!item.organization?.is_unshared}
          onClick={() => handleClick(item?.organizations)}
          style={{
            height: "100px",
            marginBottom: "10px",
            background: item.organizations?.is_unshared ? "#ebebebc7" : "",
          }}
          cover={
            <List.Item
              key={item.id}
              // @ts-ignore
              actions={[
                <Tag
                  color={item.organizations.is_owner ? "success" : "warning"}
                  icon={<CgOrganisation />}
                  key="icon"
                  style={{ borderRadius: "10px" }}
                >
                  {item.organizations.is_owner ? OWNER : INVITEE}
                </Tag>,
              ]}
              extra={
                item.organization_id === organization_id && (
                  <BsCheckCircleFill size="15" color={"green"} />
                )
              }
            >
              <Meta
                // logo will be here in
                avatar={
                  <Avatar src="https://joeschmoe.io/api/v1/random" icon={item.organizations.logo} />
                }
                title={<Typography>{item?.organizations?.name}</Typography>}
                description={item?.organizations?.organization_plan?.name}
              />
            </List.Item>
          }
        />
      )}
    />
  );
};
