/** @format */

import { useEffect, useState, } from "react";
import { useNavigate } from "react-router";
import { Checkbox, Col, Divider, Row, Space, Typography } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { endpoints, routeNames } from "static";
import { useTypedDispatch } from "store";
import { Buttonx, Toast } from "app/shared";
import { useStore } from "app/Hooks/useStore";
import { setDrawer, setOrganization } from "store/slices/authSlice";
import { useAxios, useDefaultOrganization, useSharedOrganization } from "app/Hooks";

const { DASHBOARD } = routeNames;
const { BMS_MODULE_INTEGRATIONS, USER_PROFILE } = endpoints;

export const ShareOrganization = ({ organization, toggleInnerDrawer }: any) => {
  const navigate = useNavigate();
  const { callAxios } = useAxios();
  const dispatch = useTypedDispatch();
  const { refetch } = useDefaultOrganization();
  const [loading, setLoading] = useState(false);
  const { handleUpdateUserWithRoles } = useStore();
  const [selectedModule, setModule] = useState<CheckboxValueType[]>();
  const { general_modules, handleModulePermission } = useSharedOrganization();

  useEffect(() => {
    setModule([...modules?.filter((val) => val.status).map((val) => val.name)]);
    //eslint-disable-next-line
  }, []);

  const selectModule = (checkedValues: CheckboxValueType[]) => setModule(checkedValues);

  const filters = organization?.modules_permissions?.filter((val: any) => val.status) || [];
  const mds = [...general_modules];

  const modules = filters.length ? mds.map((val) => ({ ...val, status: false })) : mds;
  general_modules.forEach((val: any, i: number) =>
    filters.forEach((md: any) => {
      if (md.general_modules_id === val.id) {
        modules[i] = {
          ...modules[i],
          status: true,
        };
      }
    })
  );

  const handleSubmit = () => {
    let payload = {
      organization_id: Number(organization.id),
      modules: handleModulePermission(selectedModule),
    };
    setLoading(true);
    callAxios({
      method: "post",
      url: BMS_MODULE_INTEGRATIONS,
      data: payload,
    })
      .then((res) => {
        if (res) {
          Toast({ message: res.message });
          dispatch(setOrganization(organization.id));
          dispatch(setDrawer(false));

          callAxios({
            url: USER_PROFILE,
            org: organization.id,
          }).then((res) => {
            setLoading(false);
            // startTransition(() => {
            toggleInnerDrawer();
            // });
            handleUpdateUserWithRoles(res);
            refetch();
            navigate(DASHBOARD);
            // setTimeout(() => window.location.reload(), 4000);
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            {organization?.platform_type === "books"
              ? "Shared Organization"
              : "Enable the modules required for your business"}
          </Typography.Title>
          <Typography.Text>
            Item, Customer & Supplier are available by default in Seebiz Expense.
          </Typography.Text>
        </Col>
        <Col span={18} style={{ top: "15px" }}>
          <Checkbox.Group
            defaultValue={modules?.filter((val) => val.status)?.map((val) => val.name) || undefined}
            style={{ width: "100%", paddingBottom: "10px" }}
            onChange={selectModule}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              {modules?.map((module) => (
                <Col span={12} key={module.general_modules_id}>
                  <Checkbox
                    disabled={!!(module.name === "Item" || module.name === "Contact")}
                    value={module.name}
                  >
                    {(module.name === 'Contact') ? 'Customer & Supplier' : module.name}
                  </Checkbox>{" "}
                </Col>
              ))}
            </Space>
          </Checkbox.Group>
          <Typography.Text>
            Note: You can change these details later in settings, if needed.
          </Typography.Text>
          <Divider />
          <Space>
            <Buttonx loading={loading} btnText="Share & Switch" clickHandler={handleSubmit} />
          </Space>
        </Col>
      </Row>
      {/* <Checkbox.Group
        defaultValue={[...modules?.filter((val) => val.status).map((val) => val.name)]}
        style={{ width: "100%" }}
        onChange={selectModule}
      >
        <Row>
          {modules?.map((module) => (
            <Col span={12} key={module.id}>
              <Checkbox style={{ paddingBottom: "10px" }} value={module.name}>
                {module.name}
              </Checkbox>
              <br />
            </Col>
          ))}
        </Row>
      </Checkbox.Group> */}
    </>
  );
};
