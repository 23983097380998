/**@format */

import { useMemo } from "react";
import { RootState, useTypedSelector } from "store";
import { useGetGeneralModulesQuery } from "store/query/organization";

export const useSharedOrganization = () => {
  const { data: general_modules = [] } = useGetGeneralModulesQuery("", {
    refetchOnMountOrArgChange: true
  });
  const { organization_id, users_organizations = [] } = useTypedSelector(
    ({ authReducer }: RootState) => authReducer
  );

  const sharedOrg = useMemo(
    () => users_organizations.find((val) => val.id === +organization_id),
    [organization_id, users_organizations]
  );

  const ownerOrg = useMemo(
    () => users_organizations.find((val) => val.id === sharedOrg?.id),
    [sharedOrg?.id, users_organizations]
  );

  const handleModulePermission = (module: any) => {
    let all_modules = general_modules.map((val: any) => ({ ...val, status: false }));
    module?.forEach((ch: any) => {
      general_modules.forEach((md: any, i: number) => {
        if (md?.name === ch) {
          all_modules[i] = {
            ...all_modules[i],
            status: true,
          };
        }
      });
    });

    return all_modules;
  };

  return {
    ownerOrg,
    sharedOrg,
    organization_id,
    general_modules,
    users_organizations,
    handleModulePermission,
  };
};
