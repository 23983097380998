// import { useState } from "react";
import { useLoading } from "app/Hooks";
import { Buttonx, PageLoader, Status } from "app/shared";
import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { axiosCall } from "services";
import { Content, Labels, endpoints, routeNames } from "static";

const { SIGNUP } = Labels;
const { SINGUP: signUp } = routeNames;
const { INVITE_CONFIRMATION } = endpoints;
const { account_verified, congratulations, account_not_verified } = Content;

export const InviteConfirmation = () => {
  const { token = "" } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [loading, setFalse, setTrue] = useLoading(false);

  const Button = () => (
    <Link to={signUp}>
      <Buttonx btnText={SIGNUP} htmlType="button" />
    </Link>
  );

  useLayoutEffect(() => {
    axiosCall({
      data: { token },
      method: "post",
      url: INVITE_CONFIRMATION,
      isAuth: false,
    }).then((res) => {
      console.log(res);
      if (process.env.REACT_APP_SSO_ENABLE === "true") {
        // setInvitationToken(token);
      }
      if (res) {
        setStatus(true);
        setTrue();
        setTimeout(() => navigate(signUp, { state: { ...res, invitation_token: token } }), 1500);
      } else {
        setStatus(true);
        setFalse();
      }
    });
  });

  return loading ? (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Status status={"success"} title={account_verified} subTitle={congratulations} extra={null} />
    </div>
  ) : !status ? (
    <PageLoader />
  ) : (
    <Status
      status={"error"}
      title={account_not_verified}
      subTitle=""
      extra={<Button key="confirm-btn" />}
    />
  );
};
