/** @format */

import { Suspense, useCallback, useState } from "react";
import { Layout } from "antd";
// import { useLocation } from "react-router-dom";
// import { routeNames } from "static";
import { MainDrawer } from "app/Layout/Drawer";
import { RouterConfig } from "./routes";
import { RootState, useTypedSelector } from "store";
import { AppHeader, PageLoader, SideBar } from "app/shared";
import { useStore } from "app/Hooks";

// const { DASHBOARD } = routeNames;

export const AppRoutes = () => {
  // const { pathname = DASHBOARD } = useLocation();

  const { id_token = "", access_token = "" } = useTypedSelector(
    (state: RootState) => state.authReducer.token || {}
  );
  const { organization_id } = useStore();
  const auth = Boolean(id_token || access_token);
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(!collapsed);

  const cacheToggle = useCallback(toggle, [collapsed]);

  return (
    <>
      <Layout
        hasSider
        className={auth ? "" : "layout-display"}
        style={{
          minHeight: "100vh",
        }}
      >
        {auth && organization_id && !window?.location.pathname.includes("/invite/accept") && (
          <SideBar />
        )}
        {auth && (
          <>
            {organization_id && !window?.location.pathname.includes("/invite/accept") && (
              <AppHeader toggle={cacheToggle} />
            )}
            <MainDrawer cacheToggle={cacheToggle} collapsed={collapsed} />
          </>
        )}
        <Suspense fallback={<PageLoader />}>
          <RouterConfig auth={auth} />
        </Suspense>
      </Layout>
    </>
  );
};
