/** @format */
import { css } from "@emotion/react";
import { Spin } from "antd";
import { BeatLoader } from "react-spinners";

const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: red;
  height: 100%;
`;

export const PageLoader = ({ height = "100vh" }: { height?: string }) => (
  <div style={{ height: height }} className="_spinner">
    <BeatLoader color="green" css={override} size={18} margin={12} loading />
  </div>
);

export const Spinner = () => {
  return (
    <div className="_spinner">
      <Spin size="large" />
    </div>
  );
};
