import { Rule } from "antd/lib/form";
import { RuleType } from "rc-field-form/lib/interface";
const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;

const passwordValidator = ({ getFieldValue }: any) => ({
  // console.log(value)
  validator(_ex: any, value: any) {
    if (value && !passwordRegex.test(value)) {
      return Promise.reject(new Error('Enter Valid Password'));
    }
    else if (!value || getFieldValue("password") === value) {


      return Promise.resolve();
    }
    // console.log("i m called else", getFieldValue)
    return Promise.reject(new Error("Password not matched!"));
  },
});

export const rules = (
  name: RuleType = "email",
  message?: string,
  is_confirm: boolean = false
): Rule[] =>
  !message
    ? [{ type: name, required: true }]
    : !is_confirm
      ? [{ required: true, message }]
      : [{ required: true, message }, passwordValidator];
