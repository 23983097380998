/** @format */

import { lazy } from "react";
export const Tax = lazy(() => import(/* webpackChunkName: 'Tax' */ "./AdminView/Tax"));
export const Currency = lazy(
  () => import(/* webpackChunkName: 'Currency' */ "./AdminView/Currency")
);
export const Contacts = lazy(
  () =>
    import(
      /* webpackChunkName: 'Contacts' */ "./AdminView/AdminSetting/Customization/Modules/Contacts"
    )
);
export const NewContacts = lazy(
  () =>
    import(
      /* webpackChunkName: 'Create Contacts' */ "./AdminView/AdminSetting/Customization/Modules/Contacts/CreateContact"
    )
);
export const EditContacts = lazy(
  () =>
    import(
      /* webpackChunkName: 'Edit Contacts' */ "./AdminView/AdminSetting/Customization/Modules/Contacts/Edit"
    )
);
export const Approvals = lazy(
  () => import(/* webpackChunkName: 'Approvals' */ "./MyView/Approvals")
);
export const Analytics = lazy(
  () => import(/* webpackChunkName: 'Analytics' */ "./MyView/Analytics")
);
export const Trips = lazy(() => import(/* webpackChunkName: 'Trips' */ "./MyView/Trips"));
export const NewTrip = lazy(
  () => import(/* webpackChunkName: 'Create Trip' */ "./MyView/Trips/Create")
);
export const EditTrip = lazy(
  () => import(/* webpackChunkName: 'Create Trip' */ "./MyView/Trips/Edit")
);
export const ViewTrip = lazy(
  () => import(/* webpackChunkName: 'Create Trip' */ "./MyView/Trips/ViewTrip")
);
export const Dashboard = lazy(
  () => import(/* webpackChunkName: 'Dashboard' */ "./MyView/Dashboard")
);
export const Register = lazy(() => import(/* webpackChunkName: 'Organization' */ "./Organization"));
export const UserRoles = lazy(
  () => import(/* webpackChunkName: 'UserRoles' */ "./AdminView/UserRoles/Tab")
);
export const CreateRoles = lazy(
  () => import(/* webpackChunkName: 'CreateRoles' */ "./AdminView/UserRoles/Role/Create")
);
export const EditRoles = lazy(
  () => import(/* webpackChunkName: 'EditRoles' */ "./AdminView/UserRoles/Role/Edit")
);
export const Organizations = lazy(
  () => import(/* webpackChunkName: 'Listing' */ "./Organization/Listing")
);
export const CreateOrganization = lazy(
  () => import(/* webpackChunkName: 'Organization_Create' */ "./Organization/Create")
);
export const EditOrganization = lazy(
  () => import(/* webpackChunkName: 'Organization_Edit' */ "./Organization/Edit")
);
export const MealPreferences = lazy(
  () =>
    import(
      /* webpackChunkName: 'MealPreferences' */ "./AdminView/AdminSetting/Preferences/MealPreferences"
    )
);
export const SeatPreferences = lazy(
  () =>
    import(
      /* webpackChunkName: 'SeatPreferences' */ "./AdminView/AdminSetting/Preferences/SeatPreferences"
    )
);
export const FlightPreferences = lazy(
  () =>
    import(
      /* webpackChunkName: 'FlightPeferences' */ "./AdminView/AdminSetting/Preferences/FlightPeferences"
    )
);
export const TimePreferences = lazy(
  () =>
    import(
      /* webpackChunkName: 'TimePreferences' */ "./AdminView/AdminSetting/Preferences/TimePreferences"
    )
);
export const CarTypePreferences = lazy(
  () =>
    import(
      /* webpackChunkName: 'CarTypePreferences' */ "./AdminView/AdminSetting/Preferences/CarTypePreferences"
    )
);
export const Expenses = lazy(() => import(/* webpackChunkName: 'Expenses' */ "./MyView/Expenses"));
export const EditExpenses = lazy(
  () => import(/* webpackChunkName: 'Expenses' */ "./MyView/Expenses/Edit")
);
export const CreateExpense = lazy(
  () => import(/* webpackChunkName: 'expense_create' */ "./MyView/Expenses/Create")
);
export const Admin_Expenses = lazy(() => import(/* webpackChunkName: 'Expenses' */ "./AdminView/Expenses"));
export const Admin_EditExpenses = lazy(
  () => import(/* webpackChunkName: 'Expenses' */ "./AdminView/Expenses/Edit")
);
export const Admin_CreateExpense = lazy(
  () => import(/* webpackChunkName: 'expense_create' */ "./AdminView/Expenses/Create")
);
export const Items = lazy(() => import(/* webpackChunkName: 'Items' */ "./MyView/Items"));

export const TAGS = lazy(() => import(/* webpackChunkName: 'Tags' */ "./AdminView/Tags"));
export const PaymentModes = lazy(
  () => import(/* webpackChunkName: 'Tags' */ "./AdminView/PaymentModes")
);
export const PurchaseRequest = lazy(
  () => import(/* webpackChunkName: 'Tags' */ "./AdminView/PurchaseRequest")
);
export const CreatePurchaseRequest = lazy(
  () => import(/* webpackChunkName: 'Tags' */ "./AdminView/PurchaseRequest/Create")
);
export const EditPurchaseRequest = lazy(
  () => import(/* webpackChunkName: 'Tags' */ "./AdminView/PurchaseRequest/Edit")
);
export const REPORTS = lazy(() => import(/* webpackChunkName: 'Reports' */ "./MyView/Reports"));
export const REPORT_DETAIL = lazy(
  () => import(/* webpackChunkName: 'Reports' */ "./MyView/Reports/ReportDetail")
);
export const ADVANCES = lazy(() => import(/* webpackChunkName: 'Reports' */ "./MyView/Advances"));
export const ADMIN_SETTINGS = lazy(
  () => import(/* webpackChunkName: 'Reports' */ "./AdminView/AdminSetting")
);
export const USER_SETTINGS = lazy(
  () => import(/* webpackChunkName: 'Reports' */ "./MyView/MyViewSetting")
);
export const Accounts = lazy(() => import(/* webpackChunkName: 'Accounts' */ "./MyView/Accounts"));
export const MODULES = lazy(
  () => import(/* webpackChunkName: 'Modules' */ "./AdminView/AdminSetting/Customization/Modules")
);
export const ModuleTrips = lazy(
  () =>
    import(
      /* webpackChunkName: 'Module Trip' */ "./AdminView/AdminSetting/Customization/Modules/Trips"
    )
);
export const ModuleExpense = lazy(
  () =>
    import(
      /* webpackChunkName: 'Module Expense' */ "./AdminView/AdminSetting/Customization/Modules/Expense"
    )
);
export const ModuleMerchant = lazy(
  () =>
    import(
      /* webpackChunkName: 'Module Merchant' */ "./AdminView/AdminSetting/Customization/Modules/Merchant"
    )
);
export const ModulePaidThrough = lazy(
  () =>
    import(
      /* webpackChunkName: 'Module Paid Throuh Account' */ "./AdminView/AdminSetting/Customization/Modules/PaidThroughAccounts"
    )
);
export const ModulePermisson = lazy(
  () =>
    import(
      /* webpackChunkName: 'Module Category' */ "./AdminView/AdminSetting/Customization/Modules/ModulePermission"
    )
);
export const ModuleCategories = lazy(
  () =>
    import(
      /* webpackChunkName: 'Module Permission' */ "./AdminView/AdminSetting/Customization/Modules/Category"
    )
);

/** Admin routes */

export const AdminTrips = lazy(() => import(/* webpackChunkName: 'Trips' */ "./AdminView/Trips"));
export const AdminNewTrip = lazy(
  () => import(/* webpackChunkName: 'Create Trip' */ "./AdminView/Trips/Create")
);
export const AdminEditTrip = lazy(
  () => import(/* webpackChunkName: 'Create Trip' */ "./AdminView/Trips/Edit")
);
export const AdminViewTrip = lazy(
  () => import(/* webpackChunkName: 'Create Trip' */ "./AdminView/Trips/ViewTrip")
);

export const AdminAdvances = lazy(
  () => import(/* webpackChunkName: 'Reports' */ "./AdminView/Advances")
);

export const AdminApprovals = lazy(
  () => import(/* webpackChunkName: 'Approvals' */ "./AdminView/Approvals")
);
