/** @format */
import { useEffect, useState } from "react";
import { Layout, Menu, Collapse } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icons, Toast } from "app/shared";
import { routeNames, Labels } from "static";
import { deleteKeyFromLS } from "utils/Storage";
import { useDefaultOrganization } from "app/Hooks";
import { usePermissions } from "app/Hooks/usePermissions";

const { Sider } = Layout;
const { Panel } = Collapse;
const { Item } = Menu;
const {
  AiOutlineDashboard,
  AiOutlineSetting,
  MdOutlineCardTravel,
  AiOutlineCheckCircle,
  AiOutlineFileText,
  // VscReport,
  // FiShoppingBag,
  BiMoney,
} = Icons;
const { _DASHBOARD, SETTINGS, _TRIPS, _APPROVALS, _EXPENSES, _ADVANCES } = Labels;

export const SideBar = () => {
  const { pathname = routeNames.DASHBOARD } = useLocation();
  const currentKey = localStorage.getItem("currentViewKey") || "1";
  const [currentViewKey, setCurrentViewKey] = useState(currentKey);
  const { isAdminPermission } = usePermissions();
  //@ts-ignore
  const [modules, setModules] = useState<string[]>();
  //@ts-ignore
  const { current_User_Organization: { organizations: { modules_permissions = [] } = {} } = {} } =
    useDefaultOrganization(false);
  const navigate = useNavigate();

  useEffect(() => {
    modules_permissions?.length > 0 &&
      setModules(modules_permissions.filter((val: any) => val.status).map((val: any) => val.slug));
  }, [modules_permissions]);

  const onChange = (key: string | string[]) => {
    localStorage.setItem("currentViewKey", key as string);
    setCurrentViewKey(key as string);
    if (key === "1") Toast({ message: "You have switched to My View" });
    else Toast({ message: "You have switched to Admin View" });

    navigate(routeNames.DASHBOARD, { state: { key } });
  };

  const handleClick = () => {
    deleteKeyFromLS("obj");
    deleteKeyFromLS("params");
  };

  return (
    <Sider
      collapsible
      collapsedWidth={70}
      className="uiux_sidebar"
      style={{
        minHeight: "100vh",
      }}
    >
      {/* <div className="logo" /> */}
      <Collapse
        accordion
        expandIconPosition="end"
        activeKey={[currentViewKey!]}
        // defaultActiveKey={[currentViewKey!]}
        onChange={onChange}
      >
        <Panel header="My view" key="1">
          <Menu theme="light" mode="inline" defaultSelectedKeys={[pathname]} onClick={handleClick}>
            <Item key="/home" icon={<AiOutlineDashboard />}>
              <Link to={routeNames.DASHBOARD} state={{ key: "1", path: { pathname } }}>
                {_DASHBOARD}
              </Link>
            </Item>
            {modules?.includes("trips") && (
              <Item key="/trips" icon={<MdOutlineCardTravel />}>
                <Link to={routeNames.TRIPS} state={{ path: { pathname } }}>
                  {_TRIPS}
                </Link>
              </Item>
            )}
            {modules?.includes("expense") && (
              <Item key="/expenses" icon={<AiOutlineFileText />}>
                <Link to={routeNames.EXPENSES} state={{ path: { pathname } }}>
                  {_EXPENSES}
                </Link>
              </Item>
            )}
            {/* {modules?.includes("item") && (
              <Item key={routeNames.ITEMS} icon={<FiShoppingBag />}>
                <Link to={routeNames.ITEMS} state={{ path: { pathname } }}>
                  {ITEMS}
                </Link>
              </Item>
            )} */}
            {/* <Item key="/reports" icon={<VscReport />}>
              <Link to={routeNames.REPORTS} state={{ path: { pathname } }}>
                {REPORTS}
              </Link>
            </Item> */}
            {modules?.includes("advances") && (
              <Item key="/advances" icon={<BiMoney />}>
                <Link to={routeNames.ADVANCES} state={{ path: { pathname } }}>
                  {_ADVANCES}
                </Link>
              </Item>
            )}
            {modules?.includes("approvals") && (
              <Item key="/approvals" icon={<AiOutlineCheckCircle />}>
                <Link to={routeNames.APPROVALS} state={{ path: { pathname } }}>
                  {_APPROVALS}
                </Link>
              </Item>
            )}
            {/* {
              <Item key="/analytics" icon={<TbFileAnalytics />}>
                <Link to={routeNames.ANALYTICS} state={{ path: { pathname } }}>
                  {ANALYTICS}
                </Link>
              </Item>
            } */}
            {/* <Item key="/settings" icon={<AiOutlineSetting />}>
              <Link to={routeNames.USER_SETTINGS} state={{ path: { pathname } }}>
                {SETTINGS}
              </Link>
            </Item> */}
          </Menu>
        </Panel>
        {isAdminPermission() && (
          <Panel header="Admin view" key="2">
            <Menu theme="light" mode="inline" defaultSelectedKeys={[pathname]}>
              <Item key="/dashboard" icon={<AiOutlineDashboard />}>
                <Link to={routeNames.DASHBOARD} state={{ key: "2", path: { pathname } }}>
                  {_DASHBOARD}
                </Link>
              </Item>
              {modules?.includes("admin_trips") && (
                <Item key={routeNames.ADMIN_TRIPS} icon={<MdOutlineCardTravel />}>
                  <Link to={routeNames.ADMIN_TRIPS} state={{ path: { pathname } }}>
                    {_TRIPS}
                  </Link>
                </Item>
              )}
              {modules?.includes("admin_expense") && (
                <Item key={routeNames.ADMIN_EXPENSES} icon={<MdOutlineCardTravel />}>
                  <Link to={routeNames.ADMIN_EXPENSES} state={{ path: { pathname } }}>
                    {_EXPENSES}
                  </Link>
                </Item>
              )}
              {/* <Item key="/reports" icon={<VscReport />}>
              <Link to={routeNames.REPORTS} state={{ path: { pathname } }}>
                {REPORTS}
              </Link>
            </Item> */}
              {modules?.includes("admin_advances") && (
                <Item key={routeNames.ADMIN_ADVANCES} icon={<BiMoney />}>
                  <Link to={routeNames.ADMIN_ADVANCES} state={{ path: { pathname } }}>
                    {_ADVANCES}
                  </Link>
                </Item>
              )}
              {modules?.includes("admin_approvals") && (
                <Item key={routeNames.ADMIN_APPROVALS} icon={<AiOutlineCheckCircle />}>
                  <Link to={routeNames.ADMIN_APPROVALS} state={{ path: { pathname } }}>
                    {_APPROVALS}
                  </Link>
                </Item>
              )}
              <Item key="/settings" icon={<AiOutlineSetting />}>
                <Link to={routeNames.ADMIN_SETTINGS} state={{ path: { pathname } }}>
                  {SETTINGS}
                </Link>
              </Item>
            </Menu>
          </Panel>
        )}
      </Collapse>
    </Sider>
  );
};
