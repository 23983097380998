import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQuery } from "./BaseQuery";
import { endpoints } from "static";

const { APPROVAL_SUMMARY } = endpoints

export const approvalSummaryQuery = createApi({
    reducerPath: "ApprovalSummary",
    keepUnusedDataFor: 3600,
    baseQuery: BaseQuery,
    endpoints: (builder) => ({
        getApprovalListing: builder.query<any, any>({
            query: () => ({ url: APPROVAL_SUMMARY, method: "get" }),
        }),
    }),
});

export const { useGetApprovalListingQuery } = approvalSummaryQuery;