import { RootState, useTypedSelector } from "store";
import { useGetOrganizationsQuery } from "store/query/organization";
import { useGetRolepermissionQuery } from "store/query/permissions";

export const useDefaultOrganization = (skip_Collapse?: boolean) => {
  const { data } = useGetRolepermissionQuery("");

  const default_org = {} as any;
  const {
    data: { organizations = [], general_modules = [] } = {},
    isLoading,
    refetch,
  } = useGetOrganizationsQuery("", {
    skip: skip_Collapse,
    // refetchOnReconnect: true,
    refetchOnFocus: true,
  });
  const {
    details: { current_organization_id = null },
  } = useTypedSelector(({ authReducer }: RootState) => authReducer);

  const primary_organization = organizations.filter(
    (org: any) => org.organizations.is_default && org.organizations.is_owner
  )[0];

  if (!primary_organization?.id) {
    default_org.is_default = organizations.length ? organizations[0] : [];
  }

  const current_User_Organization = organizations?.find(
    (org: any) => org?.organizations?.id === current_organization_id
  );

  const checkModulePermission = (moduleSlug: string) => {
    return (
      !current_User_Organization?.organizations?.modules_permissions?.find(
        ({ slug }: { slug: string }) => slug === moduleSlug
      )?.status || false
    );
  };

  const getCurrentModule = (slugs: string) => {
    return current_User_Organization?.organizations?.modules_permissions?.find(
      ({ slug }: { slug: string }) => slug === slugs
    );
  };

  return {
    refetch,
    isLoading,
    organizations,
    ...default_org,
    general_modules,
    getCurrentModule,
    checkModulePermission,
    primary_organization,
    current_User_Organization,
    permissions: data?.permissions,
  };
};
