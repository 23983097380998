/** @format */

import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "app/shared";
import { axiosCall } from "services";
import { useBool } from "app/Hooks";
import { SignUpForm, signUpSubmitProps } from "./";
import { endpoints, routeNames } from "static/routes";
import { ssoSignUpPath } from "utils";

interface LocationProps {
  name: string;
  email: string;
  invitation_token?: string;
}

const { SIGNUP, REGISTER_NEW_USER } = endpoints;
const { LOGIN } = routeNames;

export const SignUp: FC = () => {
  const navigate = useNavigate();
  const sso_signup_url = ssoSignUpPath();
  const { bool, toggle } = useBool();
  const location = useLocation()
  const { email, name, invitation_token } =
    (location.state as LocationProps) || {};
  if (process.env.REACT_APP_SSO_ENABLE === "true") window.location.href = sso_signup_url;

  const onSubmit = (data: signUpSubmitProps) => {
    data.password_confirmation = data.password;
    toggle();
    if (email) {
      console.log("i am called @ 35 ")
      axiosCall({
        data: { ...data, invitation_token },
        method: "post",
        url: REGISTER_NEW_USER,
        isAuth: false,
      }).then((res) => {
        if (res) {
          toggle();
          Toast({ message: res.message, type: "info" });
          setTimeout(() => {
            navigate(LOGIN);
          }, 1500);
        }
        toggle();
      }).catch(() => toggle());
    } else {

      axiosCall({ data, method: "post", url: SIGNUP, isAuth: false }).then((res) => {
        if (res) {
          toggle();
          Toast({ message: res.message, type: "info" });
          setTimeout(() => {
            navigate(LOGIN);
          }, 1500);
        }
        toggle();
      }).catch(() => toggle());
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <SignUpForm onSubmit={onSubmit} loading={bool} name={name} email={email} />
    </div>
  );
};