/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQuery } from "./BaseQuery";
import { endpoints } from "static";

const { PURCHASE_REQUEST } = endpoints;

export const purchaseRequestQuery = createApi({
    reducerPath: "purcahseRequest",
    keepUnusedDataFor: 3600,
    baseQuery: BaseQuery,
    endpoints: (builder) => ({
        getPurchaseRequestListing: builder.query<any, any>({
            query: (paginate) => {
                const { page = 1, pageSize = 10 } = paginate;
                return {
                    url: `${PURCHASE_REQUEST}?filter=${"all"}&page=${page}&sort_by=${"name"}&order_by=${"asc"}&view=${pageSize}&search=${""}`,
                    method: "get",
                }
            }
        }),
    }),
});

export const { useGetPurchaseRequestListingQuery } = purchaseRequestQuery;
