/**@format */

import { useState, useEffect } from "react";
import { CreateFormInterface } from "./Types";
import { useAxios, useBool } from "app/Hooks";

const initialList: CreateFormInterface = {
  expense_categories: [],
  payment_modes: [],
  taxes: [],
  currencies: [],
  tags: [],
  merchants: [],
  vendors: [],
  customers: [],
  cities: [],
  account_groups: [],
  advances: [],
  base_currency: [],
  prefrences: [],
  bool: false,
};

export const useCreateForm = (url: string, has_permission: boolean) => {
  const [createData, setCreateData] = useState<CreateFormInterface | null>(initialList);
  const { bool, toggle } = useBool();
  const { callAxios } = useAxios();

  useEffect(() => {
    if (has_permission && url) {
      toggle();
      callAxios({
        method: "get",
        url: url,
      }).then((res: any) => {
        if (res) {
          setCreateData({
            ...res,
          });
          toggle();
        }
      });
    }
    //eslint-disable-next-line
  }, [url, has_permission]);

  return {
    expense_categories: createData?.expense_categories,
    payment_modes: createData?.payment_modes,
    taxes: createData?.taxes,
    currencies: createData?.currencies,
    tags: createData?.tags,
    merchants: createData?.merchants,
    vendors: createData?.vendors,
    customers: createData?.customers,
    cities: createData?.cities,
    account_groups: createData?.account_groups,
    reports: createData?.reports,
    prefrences: createData?.preference,
    base_currency: createData?.base_currency,
    createData,
    bool,
  };
};

export const useGetEditData = (url: string, has_permission: boolean) => {
  const [getEditData, setGetEditData] = useState<CreateFormInterface | null>();
  const { bool, toggle } = useBool();
  const { callAxios } = useAxios();

  useEffect(() => {
    if (has_permission && url) {
      toggle();
      callAxios({
        method: "get",
        url: url,
      }).then((res: any) => {
        if (res) {
          setGetEditData({
            ...res,
          });
          toggle();
        }
      });
    }
    //eslint-disable-next-line
  }, [url, has_permission]);

  return {
    expenseEdit: getEditData?.expense,
    expense_categories: getEditData?.expense_categories,
    payment_modes: getEditData?.payment_modes,
    taxes: getEditData?.taxes,
    currencies: getEditData?.currencies,
    tags: getEditData?.tags,
    merchants: getEditData?.merchants,
    vendors: getEditData?.vendors,
    customers: getEditData?.customers,
    cities: getEditData?.cities,
    bool,
    prefrences: getEditData?.preference,
    base_currency: getEditData?.base_currency,
    EditData: getEditData,
  };
};
