import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { endpoints, routeNames } from "static";
import { RootState, useTypedDispatch, useTypedSelector } from "store";
import { currentUserRole, setDetails } from "store/slices/authSlice";
import { UserOrganizations } from "store/types";

export const useStore = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const {
    organization_id,
    drawer,
    users_organizations = [],
    details: { current_organization_id = null, ...rest },
  } = useTypedSelector(({ authReducer }: RootState) => authReducer);

  const primary_organization: UserOrganizations = useMemo(
    () =>
      users_organizations.filter((org: any) => org.id === current_organization_id)?.[0] ||
      ({} as UserOrganizations),
    [current_organization_id, users_organizations]
  );

  const handleUpdateUserWithRoles = useCallback((detail, status = false) => {
    const { users_organizations = [] } = detail || {};
    const orgs = users_organizations.filter((val: any) => !val.is_unshared);

    dispatch(setDetails(detail));
    current_organization_id &&
      dispatch(
        currentUserRole({
          url: endpoints.CURRENT_USER_ROLE,
        })
      );
    if (status && !orgs.length)
      navigate(routeNames.REGISTER_ORGANIZATION, {
        replace: true,
      });
    //eslint-disable-next-line
  }, []);

  return {
    drawer,
    handleUpdateUserWithRoles,
    organization_id,
    primary_organization,
    current_organization_id,
    currentOrganization: rest,
    users_organizations,
  };
};
