/** @format */
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Drawer, Row, Space, Typography, Col, Divider, Modal, Button } from "antd";
import { AiOutlineSetting } from "react-icons/ai";
import { useTypedDispatch } from "store";
import { Icons, Toast } from "app/shared";
import { routeNames, endpoints } from "static/routes";
import { Listx } from "app/containers/Organization/MenuList";
import { Logout, setDrawer } from "store/slices/authSlice";
import { RESET_STATE_ACTION_TYPE } from "store/action/resetState";
import { useGetGeneralModulesQuery } from "store/query/organization";
import { useStore } from "app/Hooks";
import { ShareOrganization } from "app/containers/Organization/ShareOrganization";
import Modules from "app/containers/Organization/Modules";

const { VscClose, VscAdd } = Icons;
const { ORGANIZATION_LISTING, LOGIN, ORGANIZATION_CREATE } = routeNames;
const { LOGOUT } = endpoints;

export const MainDrawer = ({
  cacheToggle,
  collapsed,
}: {
  collapsed: boolean;
  cacheToggle: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const { drawer, current_organization_id, users_organizations, primary_organization } = useStore();
  const [innerDrawer, setInnerDrawer] = useState(false);
  const [item, setItem] = useState<any>();
  const [selected, setSelected] = useState<any>();
  const { permissionModal } = (location.state as any) || {};
  const { data: general_modules = [] } = useGetGeneralModulesQuery("");

  useEffect(() => {
    if (permissionModal) {
      setInnerDrawer(true);
    }
  }, [permissionModal]);

  useEffect(() => {
    if (!!users_organizations && !current_organization_id) {
      dispatch(setDrawer(!!users_organizations.length && !current_organization_id));
    }
    //eslint-disable-next-line
  }, [users_organizations, current_organization_id]);

  const logout = () => {
    dispatch(Logout({ url: LOGOUT }))
      .unwrap()
      .then((res: any) => {
        if (res) {
          cacheToggle();
          dispatch({ type: RESET_STATE_ACTION_TYPE });
          localStorage.clear();
          navigate(LOGIN, { replace: true });
          Toast({ message: res.message });
        }
      });
  };

  const toggleInnerDrawer = () => {
    setInnerDrawer((prev) => !prev);
    setItem(null);
    setSelected(null);
  };

  const setInnerDrawerDetail = (item: any) => {
    item.created_by_platform === "books" && setItem({ ...item, modules: general_modules });
    setInnerDrawer(true);
    dispatch(setDrawer(false));
  };

  const handleOrgCreate = () => {
    if (current_organization_id) cacheToggle();
    dispatch(setDrawer(false));
    navigate(`${ORGANIZATION_CREATE}?org=create`);
  };

  return (
    <>
      <Modal
        width={1600}
        footer={null}
        closable={false}
        destroyOnClose
        maskClosable={!innerDrawer}
        open={innerDrawer}
        style={{
          zIndex: 1500,
          paddingLeft: "6%",
        }}
        onCancel={toggleInnerDrawer}
      >
        {Boolean(item?.id) ? (
          <ShareOrganization organization={item} toggleInnerDrawer={toggleInnerDrawer} />
        ) : (
          !Boolean(item?.id) && (
            <Modules
              selected={selected}
              innerDrawer={innerDrawer}
              toggleInnerDrawer={toggleInnerDrawer}
              defaultModules={general_modules
                ?.filter((val: any) => val.status)
                ?.map((val: any) => val.name)}
            />
          )
        )}
      </Modal>
      <Drawer
        headerStyle={{
          backgroundColor: "#f3f8fe",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        }}
        title={
          <Space
            style={{
              alignItems: "inherit",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Typography style={{ textAlign: "end" }}>
              <VscClose size={25} onClick={cacheToggle} style={{ cursor: "pointer" }} />
            </Typography>
            <Row gutter={[8, 8]}>
              <Col span={24} style={{ display: "flex", justifyContent: "space-evenly" }}>
                <img
                  alt=""
                  src={process.env.REACT_APP_ORGANIZATION_PLACEHOLDER_IMAGE}
                  width={80}
                  height={80}
                  style={{
                    borderRadius: "50%",
                    border: "1px solid #e3d9d9",
                  }}
                />
              </Col>
              <Col span={24} style={{ display: "flex", justifyContent: "space-evenly" }}>
                {primary_organization?.name}
              </Col>

              <Col span={24} style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Typography.Text
                  style={{
                    width: "100px",
                    fontSize: "12px",
                    fontWeight: "100px",
                    color: "#777",
                  }}
                >
                  {primary_organization?.company_email}
                </Typography.Text>
              </Col>
              <Col offset={7}>
                <Typography.Link
                  style={{ color: "#2485e8", fontSize: "12px", paddingRight: "4px" }}
                  className="hover-underline"
                  onClick={() => setDrawer(true)}
                >
                  My Account
                </Typography.Link>
                <Divider
                  type="vertical"
                  style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.5)", fontSize: "20px" }}
                />
                <Typography.Link
                  onClick={logout}
                  style={{ color: "#e4585a", fontSize: "12px", paddingLeft: "4px" }}
                >
                  Sign Out
                </Typography.Link>
              </Col>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "12px",
                  borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                }}
              >
                <Typography.Text style={{ fontSize: "14px" }}>My Organizations</Typography.Text>
                <Space style={{ paddingBottom: "5px" }}>
                  <Typography>
                    <AiOutlineSetting size={16} color="#2485e8" />
                  </Typography>

                  <Link to={ORGANIZATION_LISTING} onClick={cacheToggle}>
                    <Typography.Link
                      className="hover-underline"
                      style={{ fontSize: "14px", color: "#2485e8" }}
                    >
                      Manage
                    </Typography.Link>
                  </Link>
                </Space>
              </Col>
            </Row>
          </Space>
        }
        placement="right"
        onClose={cacheToggle}
        destroyOnClose
        closable={false}
        open={collapsed || drawer}
      >
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          <Button
            style={{
              display: "flex",
              alignItems: "center",
            }}
            icon={<VscAdd size={14} />}
            type="primary"
            // for Modal onClick={toggleModel}
            onClick={handleOrgCreate}
          >
            {`${current_organization_id ? "New" : "Register"} Organization`}
          </Button>
        </Space>
        <Listx openInnerDrawer={setInnerDrawerDetail} />
      </Drawer>
    </>
  );
};
